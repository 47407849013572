<template>
<div class="main">
    <!-- 头部店铺导航 -->
    <div class="header_box">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" active-text-color="#409EFF">
            <el-menu-item index="1">丽日江北店</el-menu-item>
            <el-menu-item index="2">丽日湖畔店</el-menu-item>
            <el-menu-item index="3">丽日香榭里大街店</el-menu-item>
            <el-menu-item index="4">丽日生活汇</el-menu-item>
            <el-menu-item index="5">丽日河源中心城店</el-menu-item>
        </el-menu>
        <div class="head_box_button">
            <el-tooltip class="item" effect="dark" content="此操作会将当前配置的组件，组件内的图片、活动名称等模板保存，但不保存活动路径" placement="top">
                <el-button type="primary" @click="setTemplate()">保存为模板</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="因门店差异活动路径ID不同，使用模板会将活动路径重置，URL类型的路径请操作人员自行注意是否是活动路径，活动跳转建议使用活动类型不要使用URL类型手动输入路径" placement="top">
                <el-button type="primary" @click="useTemplate()">套用模板</el-button>
            </el-tooltip>
            
            <el-button type="primary" @click="saveBhCanvas">保存</el-button>
        </div>
    </div>
    <div class="midBox">
        <!-- 左区可用组件列表 -->
        <div class="leftTab">
            <p>可用组件</p>
            <vuedraggable
             class="" 
             v-model="list1" 
             animation="300" 
             dragClass="dragClass"  
             ghostClass="ghostClass" 
             chosenClass="chosenClass" 
             @start="onStart(1)" 
             @end="onEnd1"
             :move ='onMove'
             group="site"
             >
              <transition-group>
                 <div class="itemleft" v-for="item in list1" :key="item.id" >{{item.name}}</div>
              </transition-group>
            </vuedraggable>
        </div>
        <!-- 中区效果展示区 -->
        <div class="midTab ">
            <div class="backgroundBox">
            <vuedraggable
             v-model="list2"
             class="tabBorder minheight list2" 
             group="site"  
             animation="300" 
             dragClass="dragClass"  
             ghostClass="ghostClass" 
             chosenClass="chosenClass" 
             @start="onStart(2)" 
             @end="onEnd2"
             :move="onMove">
              <transition-group>
                 <div class="item" v-for="item in list2" :key="item.id">
                     <!-- 展示轮播图 -->
                     <div v-if="item.type=='banner'" @click="editassembly(item,item.id)" class="mid_item">
                        <el-carousel height="150px" >
                           <el-carousel-item v-for="secondItem in item.pictureList" :key="secondItem.id">
                               <img :src="picsPath()+secondItem.pictureName"/>
                           </el-carousel-item>
                        </el-carousel>
                     </div>
                     <!-- 展示金刚区 -->
                     <div v-if="item.type=='glodPart'" @click="editassembly(item,item.id)" class="mid_item glodPart">
                         <div v-for="secondItem in item.pictureList" :key="secondItem.id" class="glodPartItem">
                             <img :src ="picsPath()+secondItem.pictureName"/>
                             <div class="glodWords">{{secondItem.name}}</div>
                         </div>
                     </div>
                     <!-- 展示推荐专区 -->
                     <div v-if="item.type=='recommend'" @click="editassembly(item,item.id)" class="mid_item">
                         <div v-for="secondItem in item.pictureList" :key="secondItem.id" class="m-12 recommend">
                             <img :src ="picsPath()+secondItem.pictureName"/>
                         </div>
                     </div>
                     <!-- 展示分割线 -->
                     <div v-if="item.type=='splitLine'" @click="editassembly(item,item.id)" class="mid_item">
                         <div v-for="secondItem in item.pictureList" :key="secondItem.id">----{{secondItem.name}}</div>
                     </div>
                 </div>
              </transition-group>
            </vuedraggable>
            </div>
            
        </div>
        <!-- 右区组件内容编辑区 -->
        <div class="rightTab tabBorder" v-if="editList.length>0">
            <el-form label-width="120px">
              <!-- 轮播图 -->
              <div v-if="editType == 'banner'" class="w-100" >
                <div v-for="editItem in editList" :key="editItem.id" class="w-90 modelBorder itemBox">
                    <el-form-item class="formImgBox">
                       <!-- <el-upload
                           :limit="1"
                           :action="picsPath()"
                           list-type="picture-card"
                           :on-preview="handlePictureCardPreview"
                           :on-remove="handleRemove"
                           :on-success="(response, file, fileList)=>{return handleSuccess(response, file, fileList,editItem.id)}"
                           :show-file-list="false"
                           :before-upload ="beforeUpLoad"
                           :before-remove ="beforeRemove"
                           >
                           <i class="el-icon-plus"></i>
                        </el-upload> -->
                        <UploadPics
                            :uploadType="'main'"
                            :imgLimit="1"
                            :imgsPreview="editItem.pictureName"
                            @getImgData="(x, y, z) => getImgData(x, y, z, editItem.id)"
                            :hideText ='true'
                        />
                    </el-form-item>
                    <el-form-item label="跳转方式：" class="formInput">
                        <el-radio-group v-model="editItem.isInsideMp" @change="insideUrlChange(editItem)">
                            <el-radio :label="1">内部小程序跳转</el-radio>
                            <el-radio :label="2">外部小程序跳转</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="小程序ID：" class="formInput" v-if="editItem.isInsideMp == 2">
                        <el-input clearable v-model.trim ="editItem.appId" placeholder="请输入小程序APPID"></el-input>
                    </el-form-item>
                    <el-form-item label="活动名称：" class="formInput">
                        <el-input clearable v-model.trim="editItem.name" placeholder="请输入活动名称"></el-input>
                    </el-form-item>
                    <el-form-item label="目标路径类型：" class="formInput">
                        <el-select v-model ="editItem.urlType" placeholder="请选择" @change="urlTypeChange(editItem)" :disabled="editItem.urlTypeDisabled">
                            <el-option
                            v-for="item in activeType"
                            :key="item.type"
                            :label="item.name"
                            :value="item.type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="目标路径：" class="formInput" v-if="editItem.urlType == 'url' || editItem.urlType == 'specialActive'">
                        <el-input clearable v-model.trim ="editItem.path" placeholder="请输入活动路径" v-if="editItem.urlType == 'url'"></el-input>
                        <!-- 专题活动只传ID，小程序区分处理 -->
                        <el-select v-model="editItem.path" placeholder="请选择专题活动" v-else>
                            <el-option
                            v-for="item in activeList"
                            :key="item.id"
                            :label="item.activityName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="deleteBox">
                        <el-popover
                            placement="top"
                            width="160"
                            v-model="editItem.visible">
                            <p>确定删除{{editItem.name}}</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="editItem.visible = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="deleteitems(editItem)">确定</el-button>
                            </div>
                           <i class="el-icon-delete" style="color:#F56C6C;margin-left: 18px;" slot="reference"></i>
                        </el-popover>
                        
                    </div>
                </div>
              </div>
              <!-- 金刚区 -->
              <!-- <div v-if="editType == 'glodPart'"></div> -->
              <!-- 推荐专区 -->
              <!-- <div v-if="editType == 'recommend'"></div> -->
              <div class="w-90 itemButton" >
                  <el-button @click="addMorePic()" type="primary" class="m-auto">增加图片组</el-button>
                  <el-button @click="holdassembly()" type="primary" class="m-auto">保存当前组件配置</el-button>
              </div>
              
            </el-form>
        </div>
    </div>
    
</div>
</template>


<script>
import vuedraggable from 'vuedraggable';
import {getActivityList} from "@/api/market/activity";//专区活动列表API
import { getRipoffList } from "@/api/maketing/ripoff";//砍价活动列表API
import {getFlashsaleList} from "@/api/maketing/flashsale";//限时抢购列表API
import {getGroupList} from "@/api/maketing/groupbuy";//团购活动列表API
import {saveCanvas,queryCanvas} from "@/api/market/canvas";
import UploadPics from "@/components/UploadPics";
  export default {
      name:"merchandise",
      components:{
          vuedraggable,
          UploadPics
          },
      data() {
          return {
              activeIndex:'1',
              list1:[
                  {
                      name:'轮播图',
                      type:"banner",
                      pictureList:[
                        {
                            pictureName:'',
                            name:'',
                            path:'',
                            id:0,
                            isInsideMp:1,
                            appId:"",
                            urlType:'url',
                            urlTypeDisabled:false,
                            visible:false,
                            imgVisible:false,
                        }
                      ],
                      id:0
                      },
                  {
                      name:'金刚图',
                      type:"glodPart",
                      pictureList:[
                        {
                            pictureName:'',
                            name:'',
                            path:'',
                            id:0,
                            isInsideMp:1,
                            appId:"",
                            urlType:'url',
                            urlTypeDisabled:false,
                            visible:false,
                            imgVisible:false,
                        }
                      ],
                      id:1
                      },
                  {
                      name:'推荐图',
                      type:"recommend",
                      pictureList:[
                        {
                            pictureName:'',
                            name:'',
                            path:'',
                            id:0,
                            isInsideMp:1,
                            appId:"",
                            urlType:'url',
                            urlTypeDisabled:false,
                            visible:false,
                            imgVisible:false,
                        }
                      ],
                      id:2
                      },
                  {
                      name:'分割线',
                      type:"splitLine",
                      id:3
                      },
              ],
              list1Copy:[
                  {
                      name:'轮播图',
                      type:"banner",
                      pictureList:[
                        {
                            pictureName:'',
                            name:'',
                            path:'',
                            id:0,
                            isInsideMp:1,
                            appId:"",
                            urlType:'url',
                            urlTypeDisabled:false,
                            visible:false,
                            imgVisible:false,
                        }
                      ],
                      id:0
                      },
                  {
                      name:'金刚图',
                      type:"glodPart",
                      pictureList:[
                        {
                            pictureName:'',
                            name:'',
                            path:'',
                            id:0,
                            isInsideMp:1,
                            appId:"",
                            urlType:'url',
                            urlTypeDisabled:false,
                            visible:false,
                            imgVisible:false,
                        }
                      ],
                      id:1
                      },
                  {
                      name:'推荐图',
                      type:"recommend",
                      pictureList:[
                        {
                            pictureName:'',
                            name:'',
                            path:'',
                            id:0,
                            isInsideMp:1,
                            appId:"",
                            urlType:'url',
                            urlTypeDisabled:false,
                            visible:false,
                            imgVisible:false,
                        }
                      ],
                      id:2
                      },
                  {
                      name:'分割线',
                      type:"splitLine",
                      id:3
                      },
              ],
              list2:[
              ],
              list3:[],
              drag:false,
              dragOptions:{
                  group:{
                      name: 'site',
                      pull:'clone'
                      },
                    sort: true
              },
              addList1Index:3,
              editList:[],
              bannerTempArray:{
                  pictureName:"",//图片
                  name:"",//活动名称
                  path:'',//活动路径
                  id:0,//当前组件内图片组ID
                  isInsideMp:1,//内外部小程序识别码，1内部小程序，2外部小程序跳转
                  appId:"",//外部跳转方式下，需要提供小程序ID
                  urlType:'url',//路径类型，默认url，选择活动等是为了方便交互选择，避免手动式输入
                  urlTypeDisabled:false,//是否禁用路径类型选择，当为外部小程序时禁用，只能手动输入URL
                  visible:false,//删除popover显示隐藏
                  imgVisible:false,//图片展示隐藏
                  },
              upLoadIndex:0,
              editassemblyIndex:0,
              moveId:0,
              editType:'banner',
              activeType:[//地址写入类型
                  {name:'URL',type:'url'},
                  {name:'专区活动',type:'specialActive'},
                  {name:'砍价活动',type:'ripoffActive'},
                  {name:'限时抢购',type:'flashActive'},
                  {name:'团购活动',type:'groupActive'},
              ],
              activeList:[],//活动地址选择目前包括：拼团，砍价，专区活动，抢购
              specialActiveList:[],//专区活动列表
              ripoffList:[],//砍价活动列表
              flashList:[],//限时抢购列表
              groupList:[],//团购活动列表
              templateList:[],//将当前门店配置存储为模板
          }
      },
      computed: {},
      mounted(){
        this.queryBhCanvas();
        this.getSpecialActivityList();
        // this.getgetRipoffList();
        // this.getgetflashsaleList();
        // this.getAllgroupList();
      },
      created(){},
      methods:{
        //   ----------门店导航----------
          handleSelect(e){
              this.activeIndex = e
              this.queryBhCanvas()
              this.getSpecialActivityList()
              this.editList = []
              console.log(e)
              console.log(this.activeIndex)
          },
        //   ----------拖动功能----------start
          onStart(e){
              console.log("开始拖拽",e)
              this.drag = true
          },
        //  从左往右拖动
          onEnd1(e){
              console.log("拖拽结束",e)
              this.drag = false
              let items = JSON.parse(JSON.stringify(this.list2[e.newDraggableIndex]))
              items.id = 999
              console.log(items)
              this.list1.splice(e.oldDraggableIndex,0,items)
              for(let i = 0 ;i<this.list2.length;i++){
                  this.list2[i].id = i
              }
              console.log('拖拽1号函数')
              this.list1 = JSON.parse(JSON.stringify(this.list1Copy))
              console.log(this.list1,this.list2)  
          },
          //从右边往左边拖动
          onEnd2(e){
              console.log("拖拽结束",e)
              this.drag = false
              this.list1 = JSON.parse(JSON.stringify(this.list1Copy))
              console.log(this.list1,this.list2)  
            //   return;
          },
          onMove(){
              this.moveId = this.list2.length
          },
        //   ----------拖动功能----------end


        //   ----------图片上传功能---------start
        //   handlePictureCardPreview(e){
        //       console.log(e)
        //   }, 
        //   handleRemove(e){
        //       console.log(e)
        //   },
        //   handleSuccess(response, file, fileList,index){
        //       console.log(response, file, fileList,index)
        //       console.log(this.editList)
        //       console.log(this.editList[index])
        //       this.editList[index].pictureName = response.body
        //       this.editList[index].imgVisible = true
        //   },
        //增加图片组   
        //   addMorePic(){
        //       this.editList.push(this.bannerTempArray)
        //       let index = this.editList.length-1
        //       this.editList[index].id = index
        //   },
          beforeUpLoad(file){
            console.log('上传前',file)
            // this.upLoadIndex = file
          },
          beforeRemove(file){
              console.log(file)
            //   this.upLoadIndex = e
          },
          // 获取图片数据
          getImgData(imgData, type, params, imgIndex) {
              console.log(imgData,type,params,imgIndex)
              if (type == "main") {
              console.log(imgData,params,imgIndex)
              this.editList[imgIndex].pictureName = imgData.mainImg
            //   this.dialogMenuItemData.componentContent.list[imgIndex].image = imgData.mainImg;
            //   console.log(this.dialogMenuItemData)
            }
          },
        //----------图片上传功能---------end


        // 修改编辑组件内容------------------start
        editassembly(item,index){
            this.editList = []
            console.log("选中的中区展示组件",index,item)
            this.editList = JSON.parse(JSON.stringify(item.pictureList))
            this.editassemblyIndex = index
            console.log("编辑队列",this.editList)
        },
        // 删除图片组
        deleteitems(e){
            let length = this.editList.length-1
            for(let i = length;i>=0;i--){
                if(e.id == this.editList[i].id){
                    this.editList.splice(i,1)
                }
            }
            for(let i = 0;i<this.editList.length;i++){
                this.editList[i].id = i
            }
            console.log(this.editList)
        },
        addMorePic(){
            let item = JSON.parse(JSON.stringify(this.bannerTempArray))
            item.id = this.editList.length
            this.editList.push(item)
        },
        holdassembly(){
            let tempList2 = JSON.parse(JSON.stringify(this.list2))
            console.log(tempList2,this.editList)
            tempList2.filter(item =>{
                if(item.id == this.editassemblyIndex){
                    item.pictureList = JSON.parse(JSON.stringify(this.editList))
                }
            })
            // tempList2[this.editassemblyIndex].pictureList = JSON.parse(JSON.stringify(this.editList))
            this.list2 = []
            this.list2 = JSON.parse(JSON.stringify(tempList2))
            console.log(this.list2)
        },
        // 内外部小程序路径切换
        insideUrlChange(e){
            console.log(e,e.isInsideMp)
            if(e.isInsideMp == 2){//外部小程序，地址类型只能是URL
                e.urlType = 'url'
                e.urlTypeDisabled = true
            }else{
                e.urlType = 'url'
                e.urlTypeDisabled = false
            }
            console.log(e,e.isInsideMp)
        },
        // url类型切换
        urlTypeChange(item){
            console.log(item.urlType)
            // if(item.urlType == 'specialActive'){
            //   this.activeList = JSON.parse(JSON.stringify(this.specialActiveList))
            // }else if(item.urlType == 'ripoffActive'){
            //   this.activeList = JSON.parse(JSON.stringify(this.ripoffList))
            // }else if(item.urlType == 'specialActive'){
            //   telsehis.activeList = JSON.parse(JSON.stringify(this.specialActiveList))
            // }else if(item.urlType == 'specialActive'){
            //   this.activeList = JSON.parse(JSON.stringify(this.specialActiveList))
            // }
        },
        // 获取专题活动列表---------------------区分门店
        getSpecialActivityList(){
            let params = {
                storeId: this.activeIndex,
                status: 1,
                activityName: '',
                pageNum: 1,
                pageSize: 100,
                section: 1
            };
            getActivityList(params).then(res=>{
                this.specialActiveList = res.data.body.list
                console.log(this.activeList)
                this.activeList = JSON.parse(JSON.stringify(this.specialActiveList))
            })
        },
        // 获取砍价活动列表
        getgetRipoffList(){
            let data = {
                bargainName:'',
                bargainStatus:null,
                pageNum: 100,
                pageSize: 1,
            };
            let tempArray = []
            getRipoffList(data).then(res=>{
                let list = res.data.body.list
                for(let i = 0;i<list.length;i++){
                    if(list[i].bargainStatus != '已结束'){
                        tempArray.push(list[i])
                    }
                }
                console.log(tempArray)
                this.ripoffList = tempArray
            })
        },
        // 获取限时抢购
        getgetflashsaleList(){
            let data = {
                pageSize:100,
                pageNum:1,
            }
            let tempArray = []
            getFlashsaleList(data).then(res=>{
                let list = res.data.body.list
                for(let i = 0;i<list.length;i++){
                    if(list[i].activityStatus != 3){
                        tempArray.push(list[i])
                    }
                }
                console.log(tempArray)
                this.flashList = tempArray
            })
        },
        // 团购列表获取
        getAllgroupList(){
            let datas = {}
            // let name = ''
            // let status = ''
            let params = {
                page:1,
                size:100,
                name,
                status
            }
            let tempArray = []
            // data = Object.assign(data,datas)
            getGroupList(params).then(res=>{
                let list = res.data.body.list
                for(let i = 0;i<list.length;i++){
                    if(list[i].groupStatus != 3){
                        tempArray.push(list[i])
                    }
                }
                console.log(tempArray)
                this.groupList = tempArray
            })
        },
        // 保存和获取
        saveBhCanvas(){
            let params = {
                storeId:this.activeIndex,
                tabBarCode:'BAIHUO_HOME',//写死的，百货canvas标识
                canvasJson:JSON.stringify(this.list2)   
            };
            saveCanvas(params).then(res=>{
                  console.log(res)
              })
        },
        queryBhCanvas(){
            let params={
                tabBarCode:"BAIHUO_HOME",//写死的，百货canvas标识
                storeId: Number(this.activeIndex),
            }
            queryCanvas(params).then(res=>{
                console.log(res)
                if(res.data.body){
                    this.list2 = JSON.parse(res.data.body.canvasJson)
                }else{
                    this.list2 = []
                }
                
            })
        },
        // 门店复制使用
        setTemplate(){//设置模板
        let mes = ''
            if(this.activeIndex == '1'){
                mes = '您已将江北店设置为模板，当前有效，离开、刷新页面后需要重新设置模板'
            }else if(this.activeIndex == '2'){
                mes = '您已将丽日湖畔店设置为模板，当前有效，离开、刷新页面后需要重新设置模板'
            }else if(this.activeIndex == '3'){
                mes = '您已将丽日香榭里大街店设置为模板，当前有效，离开、刷新页面后需要重新设置模板'
            }else if(this.activeIndex == '4'){
                mes = '您已将丽日生活汇设置为模板，当前有效，离开、刷新页面后需要重新设置模板'
            }else if(this.activeIndex == '5'){
                mes = '您已将丽日河源中心城店设置为模板，当前有效，离开、刷新页面后需要重新设置模板'
            }
            this.$message({
                    message: mes,
                    type: 'success'
                });
            this.templateList = JSON.parse(JSON.stringify(this.list2))
            this.templateList.filter((item=>{
                item.pictureList.filter(child=>{
                    if(child.urlType != 'url'){
                        child.path = ''
                    }
                })
            }))
            console.log(this.templateList)
        },
        useTemplate(){//使用模板
            if(this.templateList.length == 0){
                this.$message({
                    message: '请先设置模板',
                    type: 'error'
                });
            }else{
                this.list2 = JSON.parse(JSON.stringify(this.templateList))
            }
            
        }
      }
  }
</script>

<style lang="scss" scoped>
.header_box{
    position: relative;
    display: flex;
    align-items: center;
}
.head_box_button{
    position:absolute;
    top: 10px;
    right: 50px;
}
.storeList{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid grey;
    .unpointStore{
        border: none;
        float: left;
    }
    .pointStore{
        border-bottom: 1px solid blue;
        float: left;
    }   
}
.tabBorder{
    border: 1px solid grey;
    float: left;
    border-radius: 0 10px 10px 0;
}
.midBox{
    width: 98%;
    margin: 12px 0;
    display: flex;
    justify-content: flex-start;
    background-color:#F8F8FF;
}
.leftTab{
    width: 10%;
    min-width: 150px;
    p{
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
    }
}
.midTab{
    min-width: 365px;
    margin-left: 200px;
}
.rightTab{
    width: 600px;
    margin-left: 100px;
}
.itemleft{
    width: 140px;
    margin: 5px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid grey;
    text-align: center;
    background-color: #409EFF;
    color: white;
    height: 36px;
    line-height: 36px;
}
.minheight{
    min-height: 500px;
    width: 100%;
}
.list2{
    width: 316px;
    height: 562px;
    margin-left: 25px;
    margin-top: 95px;
    span{
        display: block;
        width: 100%;
        height: 100%;
        min-height: 500px;
        overflow-y:scroll;
        
    }
    .item{
        width: 95%;
        min-height:50px;
        margin: 10px auto;
        
    }
}
.mid_item{
    width: 100%;
    min-height: 50px;
    border-radius: 10px;
    background-color: dodgerblue;
    img{
        width: 100%;
        height: 100%;
    }
}
.bannerImageBox{
    width: 100px;
    height: 100px;
    img{
        width: 100%;
        height: 100%;
    }
}
.glodPart{
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .glodPartItem{
        width: 25%;
        display: flex;
        height: 60px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        img{
            width: 40px;
            height: 40px;
            border-radius: 15%;
        }
        .glodWords{
            text-align: center;
            line-height: 20px;
            font-size: 12px;
        }
    }
}
.formImgBox{
    width: 146px;
    height: 146px;
}
/deep/.el-upload--picture-card{
    width: 100px;
    height: 100px;
}
.m-12{
    margin: 12px 0;
}
.backgroundBox{
    background-image: url('../../../../assets/indexBackImg.png');
    height: 755px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.recommend{
    width: 100%;
    height: 80px;
}
.m-auto{
    margin: 12px auto;
}
.w-90{
    width: 90%;
    margin: 12px auto;
}
.w-100{
    width: 100%;
}
.modelBorder{
    border: 1px grey dotted;
    border-radius: 15px;
}
.el-form-item{
    margin: 12px auto;
    width: 90%;
}
.itemBox{
    position: relative;
    overflow: hidden;
    background-color: #F0FFFF;
}
.deleteBox{
    position:absolute;
    right: -47px;
    top: -10px;
    background-color: #FFD700;
    width: 150px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    transform:rotate(45deg)
}
.itemButton{
    display: flex;
    justify-content: space-between;
}
::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-track-piece {
    background-color: #fff;
} /* 滚动条的内层滑轨背景颜色 */
::-webkit-scrollbar-track {
    background-color: #fff;
} /* 滚动条的外层滑轨背景颜色 */
::-webkit-scrollbar-thumb {
    background-color: #d4d8e2;
} /* 滚动条的内层滑块颜色 */
::-webkit-scrollbar-button {
    background-color: #fff;
    display: none;
} 
</style>